

































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import IncotermForm from './incoterm-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

class PageIncotermRequest extends PageRequest {
}

@Component({
    components: {IncotermForm}
})
export default class Incoterm extends AbpBase {
    edit() {
        this.editModalShow = true;
    }

    //filters
    pagerequest: PageIncotermRequest = new PageIncotermRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.incoterm.list;
    };

    get loading() {
        return this.$store.state.incoterm.loading;
    }

    create() {
        this.createModalShow = true;
    }

    pageChange(page: number) {
        this.$store.commit('incoterm/setCurrentPage', page);
        this.getpage();
    }

    pagesizeChange(pagesize: number) {
        this.$store.commit('incoterm/setPageSize', pagesize);
        this.getpage();
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'incoterm/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.incoterm.pageSize;
    }

    get totalCount() {
        return this.$store.state.incoterm.totalCount;
    }

    get currentPage() {
        return this.$store.state.incoterm.currentPage;
    }

    async search() {
        await this.pageChange(1);
    }

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }
        let item = this.pagerequest.sorting.find(x=>x.fieldName === data.key)
        if (item == null){
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        }
        else{
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    columns = [{
        title: this.L('Name'),
        key: 'name',
        sortable: 'custom'
    }, {
        title: this.L('Code'),
        key: 'code',
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small',
                        disabled: !this.hasEditPermission()
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: () => {
                            this.$store.commit('incoterm/edit', params.row);
                            this.edit();
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small',
                        disabled: !this.hasDeletePermission()
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Incoterm Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'incoterm/delete',
                                        data: params.row
                                    })
                                    await this.getpage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getpage();
    }

    hasAddPermission(){
        return this.hasPermission("Pages.Incoterms.Add");
    }

    hasEditPermission(){
        return this.hasPermission("Pages.Incoterms.Edit");
    }

    hasDeletePermission(){
        return this.hasPermission("Pages.Incoterms.Delete");
    }
}
